import { twMerge } from 'tailwind-merge';

type StepBoxProps = {
  step: number;
  customClassName?: string;
};
export default function StepBox({ step, customClassName = '' }: StepBoxProps) {
  return (
    <div
      className={twMerge(
        'absolute top-[15px] left-1 mr-3 flex h-7 w-7 items-center justify-center rounded-full bg-gray-1080 text-base text-blue-50 lg:static',
        customClassName
      )}
    >
      {step}
    </div>
  );
}
