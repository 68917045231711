export const priceData = [
  {
    name: '$0-$20',
    iconLink: '',
  },
  {
    name: '$20-$100',
    iconLink: '',
  },
  {
    name: '$100-$500',
    iconLink: '',
  },
  {
    name: '$500-$2,500',
    iconLink: '',
  },
  {
    name: '>$2,500',
    iconLink: '',
  },
  {
    name: 'Any',
    iconLink: '',
  },
];
