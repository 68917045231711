import type { ChangeEvent, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Label from '@/components/Templates/Forms/Label';

type IProps = {
  min?: number;
  max?: number;
  id?: string;
  name: string;
  label: string;
  defaultValue?: string;
  labelClassName?: string;
  placeholder?: string;
  error?: string;
  iconStep?: ReactNode;
  autoFocus?: boolean;
  value?: string;
  currentStep?: number;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  inputClassName?: string;
  wrapperClassName?: string;
  registerHook?: object;
};

export default function TextArea({
  min,
  max,
  labelClassName = '',
  id,
  value,
  name,
  onFocus,
  onBlur,
  currentStep,
  autoFocus = false,
  defaultValue = '',
  label,
  placeholder,
  error,
  disabled = false,
  onChange,
  inputClassName,
  wrapperClassName,
  registerHook,
}: IProps) {
  return (
    <div
      className={`relative ${error ? 'mb-6' : 'mb-4'} ${
        wrapperClassName || ''
      }`}
    >
      <Label htmlFor={id} labelClassName={labelClassName} label={label} />
      {value !== undefined ? (
        <textarea
          id={id}
          tabIndex={!autoFocus && currentStep === undefined ? -1 : 0}
          name={name}
          autoFocus={autoFocus}
          minLength={min}
          maxLength={max}
          className={twMerge(
            `myInputText shadow w-full appearance-none rounded-md border-1 border-[#CBD5E1] p-3 text-base leading-tight text-[#334155] focus:shadow-gray-500 focus:outline-none ${
              error ? 'border-1 border-red' : ''
            }`,
            inputClassName
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      ) : (
        <textarea
          id={id}
          autoFocus={autoFocus}
          name={name}
          minLength={min}
          maxLength={max}
          className={twMerge(
            `myInputText shadow w-full appearance-none rounded-md border-1 border-[#CBD5E1] p-3 text-base leading-tight text-[#334155] focus:shadow-gray-500 focus:outline-none ${
              error ? 'border-1 border-red' : ''
            }`,
            inputClassName
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          {...registerHook}
        />
      )}
      {error && (
        <p className="absolute top-[100%] left-0 text-xs italic text-red lg:left-[40%]">
          {error}
        </p>
      )}
    </div>
  );
}
