import Link from 'next/link';

export default function Footer() {
  return (
    <footer className="border-t  w-full border-gray-300 bg-[#F3EFE9] py-4 px-6 text-center text-sm font-semibold text-gray-1060">
      <div className={'flex items-center justify-center gap-2'}>
        <Link className={'underline'} href={'/terms-and-conditions'}>
          Terms and Conditions
        </Link>
        <span>|</span>
        <Link className={'underline'} href={'/privacy-policy'}>
          Privacy Policy
        </Link>
      </div>
      <p>
        Made with love in Canada and Vietnam <br className="md:hidden"/>by the{' '}
        <a
          tabIndex={-1}
          className="cursor-pointer underline"
          target="_blank"
          href="https://mobilefolk.com/"
          rel="noreferrer"
        >
          MobileFolk
        </a>{' '}
        team. <br/>© {new Date().getFullYear()} MobileFolk Inc. All rights reserved.
      </p>
    </footer>
  );
}
