import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from 'react-device-detect';

interface DeviceDetection {
  isMobile: boolean;
  isDesktop: boolean;
  isMobileOnly: boolean;
  isTablet: boolean;
}

const useDevice = (): DeviceDetection => ({
  isMobile,
  isDesktop,
  isTablet,
  isMobileOnly,
});

export default useDevice;
