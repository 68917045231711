import Image from 'next/image';
import { useRouter } from 'next/router';

type IChooseType = {
  handleChangeStep: Function;
  IsAdsWidgetFlag: boolean;
};
export default function SelectGiftType(_props: IChooseType) {
  const router = useRouter();
  return (
    // <div
    //   className={`z-60 bg-gray-80 fixed left-0 top-0 flex h-screen w-full items-center justify-center`}
    // >
    <div
      className={`mb-select-gift-type md:top-10 relative mx-auto overflow-x-hidden rounded-[16px] bg-white px-8 pb-8 pt-6 md:w-full md:max-w-2xl`}
    >
      <div>
        <p className="text-3xl font-bold text-[#0F172A] lg:text-center">
          For everything and everyone.
        </p>
        <p className="pt-2 text-base text-[#334155]">
          The easiest way to find gift ideas for that special someone!
        </p>
        {/* <p className="pt-1 text-base text-[#334155]"> */}
        {/*  occasion and the recipient, and get gift recommendations that are just */}
        {/*  right! */}
        {/* </p> */}
      </div>
      <div className="-mx-8 md:mx-0 md:hidden">
        <Image
          priority={true}
          src="/assets/images/find-gift/find-gift-mobile.svg"
          alt="find the perfect gift"
          className="w-full"
          width={436}
          height={149}
        />
      </div>
      <div className="flex">
        <div className="hidden w-1/2 pr-2 pt-5 md:flex">
          <div
            className="min-h-[152px] w-full cursor-pointer rounded-md bg-blue-50"
            onClick={() => {
              _props.handleChangeStep(1);
              router.push({
                href: '/',
                query: { step: 1, isAdsWidgetFlag: _props.IsAdsWidgetFlag },
              });
            }}
          >
            <div className="relative h-full rounded-md">
              <div className="group relative flex h-full w-full justify-between rounded-md bg-blue-50">
                <div className="absolute text-white">
                  <div className="flex h-full w-2/3">
                    <div className="flex cursor-pointer items-center justify-start pl-8 pt-10 text-2xl font-bold">
                      <span className="">Find the perfect gift</span>
                    </div>
                  </div>
                </div>
                <img
                  src="/assets/images/find-gift/cloud-for-hover.svg"
                  alt="cloud on find the perfect gift"
                  className="absolute bottom-0 opacity-0 transition-opacity md:group-hover:opacity-100"
                />
                <img
                  src={'/assets/images/find-gift/find-gift-normal-icon.svg'}
                  alt="find the perfect gift"
                  className="absolute right-0 h-full rounded-r-md opacity-100 md:group-hover:opacity-0"
                />
                <img
                  src={'/assets/images/find-gift/find-gift-smile-icon.svg'}
                  alt="find the perfect gift"
                  className="absolute right-0 h-full rounded-r-md opacity-0 md:group-hover:opacity-100"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden w-1/2 pl-2 pt-5 md:flex">
          <div
            className="min-h-[152px] w-full cursor-pointer rounded-md bg-blue-50"
            onClick={() => {
              _props.handleChangeStep(8);
              router.push({
                href: '/',
                query: { step: 8, isAdsWidgetFlag: _props.IsAdsWidgetFlag },
              });
            }}
          >
            <div className="relative h-full rounded-md">
              <div className="group relative flex h-full w-full justify-between rounded-md bg-pink-400">
                <div className="text-white">
                  <div className="flex h-full w-2/3">
                    <div className="flex cursor-pointer items-center justify-start pl-8 text-2xl font-bold">
                      <span className="">Set gift reminder</span>
                    </div>
                  </div>
                </div>
                <img
                  src="/assets/images/find-gift/cloud-for-hover.svg"
                  alt="cloud on find the perfect gift"
                  className="absolute bottom-0 opacity-0 transition-opacity md:group-hover:opacity-100"
                />
                <img
                  src="/assets/images/set-gift/set-a-gift-normal-icon.svg"
                  alt="set a gift reminder"
                  className="absolute right-0 h-full rounded-r-md opacity-100 md:group-hover:opacity-0"
                />
                <img
                  src="/assets/images/set-gift/set-a-gift-smile-icon.svg"
                  alt="set a gift reminder"
                  className="absolute right-0 h-full rounded-r-md opacity-0 md:group-hover:opacity-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-1 block pt-5 md:hidden">
        <div
          className="mb-3 cursor-pointer"
          onClick={() => {
            _props.handleChangeStep(1);
            router.push({
              href: '/',
              query: { step: 1, isAdsWidgetFlag: _props.IsAdsWidgetFlag },
            });
          }}
        >
          <Image
            src="/assets/images/find-gift/find-gift-mobile-op.svg"
            alt="find the perfect gift"
            className="w-full"
            width={436}
            height={149}
          />
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            _props.handleChangeStep(8);
            router.push({
              href: '/',
              query: { step: 8, isAdsWidgetFlag: _props.IsAdsWidgetFlag },
            });
          }}
        >
          <Image
            src="/assets/images/set-gift/set-gift-mobile-op.svg"
            alt="set a gift reminder"
            className="w-full"
            width={436}
            height={149}
          />
        </div>
      </div>
    </div>
    // </div>
  );
}
