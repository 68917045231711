export const typeOfGiftsData = [
  {
    name: 'Cute',
    iconLink: '😍',
  },
  {
    name: 'Practical',
    iconLink: '👏',
  },
  {
    name: 'Sentimental',
    iconLink: '😊',
  },
  {
    name: 'Funny',
    iconLink: '🤣',
  },
  {
    name: 'Novelty',
    iconLink: '😮',
  },
  {
    name: 'Romantic',
    iconLink: '❤️',
  },
  {
    name: 'Sexy',
    iconLink: '👙',
  },
  {
    name: 'Beautiful',
    iconLink: '🤩',
  },
  {
    name: 'Personalized',
    iconLink: '🎩',
  },
  {
    name: 'Handmade',
    iconLink: '🧶',
  },
  // {
  //   name: 'Retirement',
  //   iconLink: '/assets/images/icon/retirement.svg',
  // },
];
