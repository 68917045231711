import { Manrope } from 'next/font/google';
import type { ReactNode } from 'react';

import Footer from '@/components/Templates/Footer';
import Header from '@/components/Templates/Header';
import Meta from '@/components/Templates/Meta';

type IMainProps = {
  title: string;
  description: string;
  canonical?: string;
  children: ReactNode;
  step?: number;
};

const manrope = Manrope({ subsets: ['latin'] });

const MainLayout = (props: IMainProps) => {
  return (
    <>
      <Meta
        title={props.title}
        description={props.description}
        canonical={props.canonical}
      />
      <div
        className={`flex min-h-screen w-full flex-col bg-[#F3EFE9] ${manrope.className}`}
      >
        <div className={`content_bg flex flex-1 flex-col`}>
          <Header />
          <main
            className={`relative flex w-full flex-1 flex-col items-center md:justify-center`}
          >
            {props.children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
