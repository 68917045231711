import 'swiper/css';

import { isNull } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';

import CardContent from '@/components/Templates/CardContent';
import Button from '@/components/Templates/Forms/Button';
import Input from '@/components/Templates/Forms/Input';
import StepBox from '@/components/Templates/Forms/StepBox';
import TextArea from '@/components/Templates/Forms/TextArea';
import GiftCard from '@/components/Templates/GiftCard';
import StepsProgress from '@/components/Templates/StepsProgress';
import { genderList } from '@/constants/genderList';
import { objectivesData } from '@/constants/objectivesData';
import { priceData } from '@/constants/priceData';
import { typeOfGiftsData } from '@/constants/typeOfGiftsData';
import useDevice from '@/helpers/device';
import { formatQuerySearch } from '@/helpers/formatQuerySearch';
import generateUUID from '@/helpers/generateUUID';
import { localStorage } from '@/helpers/localStorage';
import { sessionStorage } from '@/helpers/sessionStorage';
import type { AppState } from '@/redux/rootReducer';
import type { ITextPrompt } from '@/services/users/redux/types';

type FindTheGiftFormProps = {
  inputData: ITextPrompt;
  isSearchOption?: boolean;
  IsAdsWidgetFlag?: boolean;
  handleChangeStep?: (stepVal: number) => void;
  handleSetInputData: (data: ITextPrompt) => void;
};
export default function FindTheGiftForm({
  handleChangeStep,
  isSearchOption = false,
  IsAdsWidgetFlag = false,
  handleSetInputData,
  inputData,
}: FindTheGiftFormProps) {
  const isFocusTextRef = useRef(false);
  const countPressTab = useRef(1);
  const users = useSelector((state: AppState) => state.users);
  const router = useRouter();
  const { isMobile } = useDevice();
  const { currentStep } = users;
  const swiperRef = useRef<any>(null);
  const [showListGenders, setShowListGenders] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [activeGenderIndex, setActiveGenderIndex] = useState(0);

  function handleChangeCurrentInputData(field: string, data: any) {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }

    switch (field) {
      case 'object':
        handleSetInputData({ ...inputData, object: data });
        break;
      case 'objectives':
        handleSetInputData(data);
        break;
      case 'inputObjectives':
        handleSetInputData({ ...inputData, inputObjectives: data });
        break;
      case 'intentions':
        handleSetInputData({ ...inputData, intentions: data });
        break;
      case 'age':
        handleSetInputData({ ...inputData, age: data });
        break;
      case 'gender':
        handleSetInputData({ ...inputData, gender: data });
        break;
      case 'typesOfGifts':
        handleSetInputData(data);
        break;
      case 'inputTypesOfGifts':
        handleSetInputData({ ...inputData, inputTypesOfGifts: data });
        break;
      case 'price':
        handleSetInputData(data);
        break;
      default:
        break;
    }
  }

  function handleValidateInputData() {
    if (!inputData) return false;
    const {
      object,
      inputTypesOfGifts,
      inputObjectives,
      objectives,
      intentions,
      typesOfGifts,
      price,
    } = inputData;
    switch (currentStep) {
      case 1:
        if (object) return true;
        setErrors({ object: 'Can not be empty!' });
        return false;
      case 2:
        if (objectives.length > 0 || inputObjectives) return true;
        setErrors({ objectives: 'You need to pick one or fill in the input!' });
        return false;
      case 3:
        if (intentions) return true;
        setErrors({ intentions: 'Can not be empty!' });
        return false;
      case 4:
        if (typesOfGifts.length > 0 || inputTypesOfGifts) return true;
        setErrors({
          typesOfGifts: 'You need to pick one or fill in the input!',
        });
        return false;
      case 5:
        if (price) return true;
        setErrors({ price: 'Can not be empty!' });
        return false;
      default:
        return false;
    }
  }

  function handleExplore() {
    sessionStorage.set(window, 'inputData', JSON.stringify(inputData));
    const txtSearch = formatQuerySearch(inputData);
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      const uuId = localStorage.get(window, 'requestId');
      if (isNull(uuId)) {
        localStorage.set(window, 'requestId', generateUUID());
      }
    }
    router.push({
      href: '/',
      query: {
        q: txtSearch || null,
        step: 6,
        isAdsWidgetFlag: IsAdsWidgetFlag,
      },
    });
  }

  function handleIsCanNextStep() {
    if (!inputData) return false;
    const {
      object,
      objectives,
      inputObjectives,
      inputTypesOfGifts,
      intentions,
      typesOfGifts,
      price,
    } = inputData;
    switch (currentStep) {
      case 1:
        return !!object;
      case 2:
        return objectives.length > 0 || inputObjectives;
      case 3:
        return !!intentions && !isFocusTextRef.current;
      case 4:
        return typesOfGifts.length > 0 || inputTypesOfGifts;
      case 5:
        return !!price;
      default:
        return false;
    }
  }

  function handleOnKeyUp(e: any) {
    if (!handleChangeStep) return;
    if (e.key === 'Enter' && !showListGenders) {
      if (!handleIsCanNextStep()) return;
      swiperRef.current.slideNext();
      sessionStorage.set(window, 'inputData', JSON.stringify(inputData));
      if (currentStep < 5) {
        handleChangeStep(currentStep + 1);
      } else if (currentStep === 5) {
        handleExplore();
      }
    } else if (e.key === 'Tab' && currentStep === 3) {
      if (countPressTab.current % 3 === 0) {
        setShowListGenders(true);
      }
      if (showListGenders) {
        setShowListGenders(false);
      }
    } else if (currentStep === 3 && showListGenders) {
      if (e.key === 'ArrowDown') {
        setActiveGenderIndex((prev) =>
          prev + 1 > genderList.length - 1 ? 0 : prev + 1
        );
      } else if (e.key === 'ArrowUp') {
        setActiveGenderIndex((prev) =>
          prev - 1 < 0 ? genderList.length - 1 : prev - 1
        );
      } else if (e.key === 'Enter') {
        handleChangeCurrentInputData('gender', genderList[activeGenderIndex]);
        setShowListGenders(false);
      }
    }
  }

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('keyup', handleOnKeyUp);
      return () => {
        window.removeEventListener('keyup', handleOnKeyUp);
      };
    }
    return () => {};
  }, [inputData, currentStep, showListGenders, activeGenderIndex]);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentStep - 1);
    }
  }, [currentStep]);
  if (!inputData) return <></>;
  return (
    <Fade>
      <section
        className={`relative flex h-full w-full flex-col rounded-xl-1 bg-white lg:h-screen lg:max-h-[500px] ${
          isSearchOption ? '' : 'max-w-[95vw] px-3 pb-3 lg:max-w-4.5xl'
        } lg:mx-auto lg:px-6`}
      >
        {currentStep > 1 && (
          <div className="relative text-center">
            <StepsProgress totalSteps={5} currentStep={currentStep} />
            <div
              onClick={() => {
                if (!handleChangeStep) return;
                swiperRef.current.slidePrev();
                handleChangeStep(currentStep - 1);
              }}
              className={
                'absolute top-1/2 left-0 ml-2 flex -translate-y-1/2 cursor-pointer items-center gap-1 text-xs font-semibold'
              }
            >
              <img
                className={'h-3 w-3'}
                src={'/assets/images/icon/arrow-back-black.svg'}
                alt={'arrow-back-black'}
              />
              <span className={'hidden text-gray-750 lg:block'}>Back</span>
            </div>
          </div>
        )}
        <div
          className={`relative w-full flex-1 ${
            currentStep === 5 ? 'border-t-1' : 'border-y-1'
          } border-gray-1050`}
        >
          <Swiper
            initialSlide={currentStep - 1}
            slidesPerView={1}
            spaceBetween={0}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            allowTouchMove={false}
            className={'h-full'}
            modules={[Navigation]}
            onSlideChange={() => {}}
            onSwiper={() => {}}
          >
            <SwiperSlide
              key={'step-one'}
              style={{ display: 'flex' }}
              className={'flex-col'}
            >
              <CardContent />
              <div className={'text-center'}>
                <StepsProgress totalSteps={5} currentStep={currentStep} />
              </div>
              <div
                className={`flex flex-1 flex-col justify-center lg:justify-between ${
                  isSearchOption
                    ? 'items-center lg:items-start'
                    : 'items-center'
                }  lg:flex-row  ${isSearchOption ? 'lg:py-6' : 'lg:px-4'}`}
              >
                <div className={'flex w-full items-center'}>
                  {isSearchOption && <StepBox step={1} />}
                  <Input
                    id={'object'}
                    autoFocus={false}
                    label="Who is receiving this gift?"
                    placeholder="e.g. My dad, my co-worker, my best friend..."
                    name="object"
                    labelClassName={`lg:text-xl  mr-0 text-base ${
                      isSearchOption ? 'text-left' : 'lg:text-left text-center'
                    } font-semibold lg:mb-0 mb-4`}
                    inputClassName={
                      'flex-1  px-4 py-3 rounded-xl border-gray-350'
                    }
                    value={inputData.object}
                    wrapperClassName={
                      'block lg:flex flex-1 gap-16 items-center'
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 300) return;
                      if (value.length === 1 && value === ' ') return;
                      handleChangeCurrentInputData('object', value);
                    }}
                    error={errors?.object || ''}
                    type="text"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={'step-two'}>
              <div
                className={`flex h-full flex-1 flex-col justify-center lg:justify-between ${
                  isSearchOption
                    ? 'items-center lg:items-start'
                    : 'items-center'
                }  py-4 lg:flex-row lg:gap-10  ${
                  isSearchOption ? 'lg:py-6' : 'lg:px-4'
                }`}
              >
                <div className={'flex w-full justify-between lg:w-1/3'}>
                  {isSearchOption && <StepBox step={2} />}
                  <p
                    className={`mb-4 flex-1 px-12 ${
                      isSearchOption
                        ? 'ml-2 text-left lg:ml-0'
                        : 'text-center lg:text-left'
                    } text-base font-bold text-gray-750 lg:mb-0 lg:px-0  lg:text-xl`}
                  >
                    Tell us about the special occasion that you are buying gifts
                    for
                  </p>
                </div>
                <div className={''}>
                  <ul
                    className={
                      'mx-auto mb-2 grid grid-cols-12 flex-wrap justify-center gap-2 lg:justify-start'
                    }
                  >
                    {objectivesData.map((card) => (
                      <GiftCard
                        field={'objectives'}
                        inputData={inputData}
                        handleSetInputData={(data: any) =>
                          handleChangeCurrentInputData('objectives', data)
                        }
                        key={card.name}
                        name={card.name}
                        iconLink={card.iconLink}
                      />
                    ))}
                  </ul>
                  <Input
                    id={'objectives'}
                    currentStep={currentStep === 2 ? currentStep : undefined}
                    autoFocus={false}
                    label={''}
                    placeholder="Halloween, Thanksgiving Day, New Year’s Day, …"
                    name="objectives"
                    wrapperClassName={'w-full'}
                    inputClassName={
                      'flex-1 w-full px-4 py-3 rounded-xl border-gray-350'
                    }
                    type="text"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 300) return;
                      if (value.length === 1 && value === ' ') return;
                      handleChangeCurrentInputData('inputObjectives', value);
                    }}
                    error={errors?.objectives || ''}
                    value={inputData.inputObjectives || ''}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={'step-three'}>
              <div
                className={`flex h-full flex-1 flex-col justify-center lg:justify-between ${
                  isSearchOption
                    ? 'items-center lg:items-start'
                    : 'items-center'
                } lg:flex-row lg:gap-10  ${
                  isSearchOption ? 'lg:py-6' : 'lg:px-4'
                }`}
              >
                <div className={'flex w-full flex-1 py-4'}>
                  {isSearchOption && (
                    <StepBox customClassName={'mr-0 mt-0'} step={3} />
                  )}
                  <div
                    className={`flex w-full flex-1 flex-col justify-center ${
                      isSearchOption ? '' : ''
                    }`}
                  >
                    <TextArea
                      id={'intentions'}
                      currentStep={currentStep === 3 ? currentStep : undefined}
                      autoFocus={false}
                      name={'intentions'}
                      label={
                        'Tell us about the recipient, in your own words. What hobbies and interests do they have? What are some things or activities they love?'
                      }
                      iconStep={isSearchOption ? <StepBox step={3} /> : ''}
                      placeholder={
                        'e.g.: My dad loves basketball, jogging, making barbecue, and playing with his grandchildren.'
                      }
                      labelClassName={`lg:w-[35%] px-6 text-gray-750 text-base lg:text-xl ${
                        isSearchOption
                          ? 'text-left ml-4 lg:ml-0'
                          : 'lg:text-left text-center'
                      } font-semibold lg:mb-0`}
                      inputClassName={
                        'flex-1 lg:w-[65%] h-36 resize-none px-4 py-3 rounded-xl border-gray-350'
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length > 300) return;
                        if (
                          value.length === 1 &&
                          (value === ' ' || value === '\n')
                        )
                          return;
                        handleChangeCurrentInputData(
                          'intentions',
                          e.target.value
                        );
                      }}
                      onFocus={() => {
                        isFocusTextRef.current = true;
                        countPressTab.current = 1;
                      }}
                      onBlur={() => {
                        isFocusTextRef.current = false;
                      }}
                      error={errors?.intentions || ''}
                      value={inputData.intentions}
                      wrapperClassName={'block lg:flex items-center gap-10'}
                    />
                    <Input
                      id={'age'}
                      currentStep={currentStep === 3 ? currentStep : undefined}
                      autoFocus={false}
                      label="What is their age?"
                      placeholder="Optional"
                      name="age"
                      labelClassName={
                        'w-[30%] sm:w-[35%] text-base text-gray-750 px-0 font-semibold lg:mb-0 mb-2'
                      }
                      inputClassName={
                        'flex-1 w-[70%] sm:w-[65%] px-4 py-3 rounded-xl border-gray-350'
                      }
                      wrapperClassName={
                        'flex mb-4 items-center gap-4 sm:gap-10'
                      }
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.length > 300) return;
                        if (e.target.value.trim().length <= 2) {
                          handleChangeCurrentInputData('age', e.target.value);
                        }
                      }}
                      onFocus={() => {
                        countPressTab.current = 2;
                      }}
                      value={inputData.age}
                    />
                    <div className={'flex items-center gap-4 sm:gap-10'}>
                      <span
                        className={
                          'mb-2 w-[30%] text-base font-semibold text-gray-750 sm:w-[35%] sm:whitespace-nowrap lg:mb-0'
                        }
                      >
                        What is their gender?{' '}
                      </span>
                      <div className={'relative flex-1'}>
                        <div
                          onClick={() => setShowListGenders(!showListGenders)}
                          className={twMerge(
                            `shadow w-full  rounded-md border-1 border-gray-350 p-3 leading-tight text-gray-750 focus:shadow-gray-500`,
                            'flex w-full items-center rounded-xl border-gray-350 px-4 py-3'
                          )}
                        >
                          <input
                            className={
                              'pointer-events-none flex-1 focus:outline-0'
                            }
                            readOnly={true}
                            onFocus={() => {
                              if (!isMobile) {
                                countPressTab.current = 3;
                              }
                            }}
                            tabIndex={currentStep === 3 ? 0 : -1}
                            value={inputData.gender}
                            placeholder={'Optional'}
                          />
                          <img
                            className={`${
                              showListGenders ? 'rotate-180' : ''
                            } transition-transform`}
                            src={'/assets/images/icon/arrow-down-black.svg'}
                            alt={'arrow-down'}
                          />
                        </div>
                        {showListGenders && (
                          <ul
                            className={
                              'absolute bottom-[100%] left-0 z-40 w-full rounded-md border-1 border-gray-350 bg-white text-sm'
                            }
                          >
                            {genderList.map((gen, index) => (
                              <li
                                key={gen}
                                onClick={() => {
                                  handleChangeCurrentInputData(
                                    'gender',
                                    genderList[index]
                                  );
                                  setShowListGenders(false);
                                }}
                                className={`${
                                  activeGenderIndex === index
                                    ? 'bg-gray-1050'
                                    : ''
                                } border-b-1 border-b-gray-350 py-2 px-4 transition-colors hover:bg-gray-1050`}
                              >
                                {gen}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={'step-four'}>
              <div
                className={`flex h-full flex-1 flex-col justify-center lg:justify-between ${
                  isSearchOption
                    ? 'items-center lg:items-start'
                    : 'items-center'
                } lg:flex-row lg:gap-10  ${
                  isSearchOption ? 'lg:py-6' : 'lg:px-4'
                }`}
              >
                <div
                  className={
                    'flex w-full justify-between gap-3 lg:w-[58%] lg:gap-0'
                  }
                >
                  {isSearchOption && <StepBox step={4} />}
                  <p
                    className={`mb-4 flex-1 px-12 ${
                      isSearchOption
                        ? 'ml-2 text-left lg:ml-0'
                        : 'text-center lg:text-left'
                    } text-base font-bold text-gray-750 lg:px-0  lg:text-xl`}
                  >
                    What types of gifts would the recipient enjoy, or would be
                    appropriate for the occasion?
                  </p>
                </div>
                <div className={'lg:w-full'}>
                  <ul
                    className={
                      'mx-auto mb-2 grid grid-cols-12 flex-wrap justify-center gap-2 lg:justify-start'
                    }
                  >
                    {typeOfGiftsData.map((card) => (
                      <GiftCard
                        field={'typesOfGifts'}
                        inputData={inputData}
                        handleSetInputData={(data: any) =>
                          handleChangeCurrentInputData('typesOfGifts', data)
                        }
                        key={card.name}
                        name={card.name}
                        iconLink={card.iconLink}
                      />
                    ))}
                  </ul>
                  <Input
                    label={''}
                    currentStep={currentStep === 4 ? currentStep : undefined}
                    autoFocus={false}
                    placeholder="Gift types: Arts, Eco-Friendly & Organic, Exercise & Wellness, ..."
                    name="typesOfGifts"
                    wrapperClassName={'w-full'}
                    inputClassName={
                      'flex-1 w-full px-4 py-3 rounded-xl border-gray-350'
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 300) return;
                      if (value.length === 1 && value === ' ') return;
                      handleChangeCurrentInputData('inputTypesOfGifts', value);
                    }}
                    error={errors?.typesOfGifts || ''}
                    type="text"
                    value={inputData.inputTypesOfGifts || ''}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={'step-five'}>
              <div
                className={`flex h-full flex-1 flex-col justify-center lg:justify-between ${
                  isSearchOption
                    ? 'items-center lg:items-start'
                    : 'items-center'
                } gap-6  ${isSearchOption ? 'lg:py-6' : 'lg:px-4'}`}
              >
                <div
                  className={`flex flex-1 flex-col justify-center border-y-1 border-gray-1050 lg:flex-row lg:justify-between  ${
                    isSearchOption
                      ? 'items-center py-4 lg:items-start'
                      : 'items-center py-4'
                  }`}
                >
                  <div className={'mb-4 flex w-full lg:w-1/3'}>
                    {isSearchOption && <StepBox step={5} />}
                    <p
                      className={`flex-1  ${
                        isSearchOption
                          ? 'ml-3 px-12 text-left lg:ml-0 lg:px-0'
                          : 'text-center lg:text-left'
                      } text-base font-bold text-gray-750   lg:text-xl`}
                    >
                      What price range is appropriate?
                    </p>
                  </div>
                  <div className={''}>
                    <ul
                      className={
                        'mx-auto mb-2 grid grid-cols-12 flex-wrap justify-center gap-2 text-gray-750 lg:justify-start'
                      }
                    >
                      {priceData.map((card) => (
                        <GiftCard
                          field={'price'}
                          inputData={inputData}
                          handleSetInputData={(data) => {
                            handleChangeCurrentInputData('price', data);
                          }}
                          key={card.name}
                          name={card.name}
                          iconLink={card.iconLink}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <ReCAPTCHA */}
                {/*  className="recaptcha flex justify-center pb-2" */}
                {/*  sitekey={configs.GOOGLE_CAPTCHA_SITE_KEY} */}
                {/*  ref={captchaRef} */}
                {/*  onChange={onReCAPTCHAChange} */}
                {/* /> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {currentStep < 5 && (
          <div
            onClick={() => {
              if (
                !handleChangeStep ||
                currentStep > 6 ||
                !handleValidateInputData()
              )
                return;
              swiperRef.current.slideNext();
              sessionStorage.set(
                window,
                'inputData',
                JSON.stringify(inputData)
              );
              handleChangeStep(currentStep + 1);
            }}
            className={`relative ${
              handleIsCanNextStep() ? '' : 'opacity-50'
            } mx-auto mt-3 flex w-40 cursor-pointer items-center justify-center gap-2 rounded-8 bg-gray-1050 text-center transition-colors hover:bg-gray-1090`}
          >
            <Button
              submitType={false}
              wrapClass={'text-gray-750 w-full bg-transparent my-0'}
              text={'Next'}
            />
            <img
              className={'absolute right-2 mr-2'}
              src="/assets/images/icon/arrow-next-black.svg"
              alt="arrow-next-black"
            />
          </div>
        )}
        {currentStep === 5 && (
          <div
            className={`relative ${
              handleIsCanNextStep() ? 'bg-green-101' : 'bg-green-99'
            } fade-in-custom mx-auto mt-3 flex w-40 cursor-pointer items-center justify-center gap-2 rounded-8 text-center transition-colors hover:bg-green-102`}
            onClick={(e) => {
              if (handleValidateInputData()) {
                e.stopPropagation();
                handleExplore();
              }
            }}
          >
            <Button
              text="Explore"
              submitType={false}
              wrapClass={'text-white w-full bg-transparent my-0'}
            />
          </div>
        )}
      </section>
    </Fade>
  );
}
