import { twMerge } from 'tailwind-merge';

type IProps = {
  submitType: boolean;
  text: string;
  disable?: boolean;
  wrapClass?: string;
};
export default function Button(props: IProps) {
  return (
    <button
      tabIndex={-1}
      className={twMerge(
        `rounded hover:bg-blue-700 w-auto rounded-md py-3 text-sm font-bold focus:outline-none ${
          props.disable ? 'bg-gray-350 ' : 'bg-gray-950 '
        }`,
        `${props.wrapClass}`
      )}
      type={props.submitType ? 'submit' : 'button'}
    >
      {props.text}
    </button>
  );
}
