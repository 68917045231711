export function paramsToObject(params: string) {
  const obj: any = {};
  const para = new URLSearchParams(params);
  // @ts-ignore
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of para) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      // @ts-ignore
      if (Array.isArray(obj[key])) {
        // @ts-ignore
        obj[key].push(value);
      } else {
        // @ts-ignore
        obj[key] = [obj[key], value];
      }
    } else {
      // @ts-ignore
      obj[key] = value;
    }
  }
  return Object.keys(obj).length === 0 ? null : obj;
}
