export function formatQuerySearch(data: object) {
  let txtSearch = '';
  const entries = Object.entries(data);
  entries.forEach(([keyName, keyValue]) => {
    if (Array.isArray(keyValue)) {
      txtSearch += `&${keyName}=${keyValue.join(',')}`;
    } else {
      txtSearch += `&${keyName}=${keyValue}`;
    }
  });
  return txtSearch;
}
