import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { sessionStorage } from '@/helpers/sessionStorage';
import type { AppState } from '@/redux/rootReducer';

const Header = () => {
  const users = useSelector((state: AppState) => state.users);
  const router = useRouter();
  return (
    <header className="relative z-20 w-full">
      {users.currentStep > 0 && (
        <div
          onClick={() => {
            sessionStorage.clear(window);
            router.push('/');
          }}
          className="absolute left-1 top-1 mt-1 flex cursor-pointer items-center justify-start lg:pl-3"
        >
          <img
            className={'h-4 md:h-auto'}
            src="/assets/images/icon/arrow-back-tail-black.svg"
            alt="arrow-tail-back-black"
          />
          <p className="hidden py-2 pl-3 text-lg font-bold text-gray-750 md:block">
            GiftRaccoon
          </p>
        </div>
      )}
      <div className="border-b   border-gray-300 py-3 text-center">
        <div
          onClick={() => {
            sessionStorage.clear(window);
            router.push('/');
          }}
          className="inline-flex w-auto items-center justify-center"
        >
          <img
            className={'pointer-events-none h-12 w-auto lg:h-20'}
            srcSet={'/assets/images/giftraccoon-logo.png 2x'}
          />
          <h1 className="text-xl font-bold text-gray-750 md:text-32xl">
            GiftRaccoon
          </h1>
        </div>
      </div>
    </header>
  );
};

export default Header;
