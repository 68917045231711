export default function CardContent() {
  return (
    <div className="relative mt-6 flex overflow-hidden rounded-lg bg-blue-50">
      <div className="w-3/4 text-white">
        <div className="py-4 pl-5">
          <div className="flex cursor-pointer items-center justify-start pr-4 pb-2 text-xl font-bold sm:pr-0">
            {/* <img src="/assets/images/icon/arrow-back-white.svg" alt="" /> */}
            <span className="pt-[2px]">Find the perfect gift</span>
          </div>
          <p className="text-sm sm:pr-20 lg:w-4/5">
            Type in your ideas for your special event, and tell us more about
            your special someone.
          </p>
        </div>
      </div>
      <div className="flex">
        <img
          src="/assets/images/find-gift/find-gift-form-icon.svg"
          alt="find the perfect gift"
          className="hidden md:block"
        />
        <img
          src="/assets/images/find-gift/find-gift-form-icon-mb.svg"
          alt="find the perfect gift"
          className="md:pt-auto relative -top-1 right-1 block scale-150 md:hidden"
        />
      </div>
    </div>
  );
}
