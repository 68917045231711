export const objectivesData = [
  {
    name: 'Christmas',
    iconLink: '🎄',
  },
  {
    name: 'Birthday',
    iconLink: '🎂',
  },
  {
    name: 'Wedding',
    iconLink: '💍',
  },
  {
    name: 'Anniversary',
    iconLink: '📅',
  },
  {
    name: "Mother's Day",
    iconLink: '👩‍👧',
  },
  {
    name: "Father's Day",
    iconLink: '👨‍👦',
  },
  {
    name: "Valentine's Day",
    iconLink: '💝',
  },
  {
    name: 'Graduation',
    iconLink: '🎓',
  },
  {
    name: 'Baby Shower',
    iconLink: '🍼',
  },
  {
    name: 'Housewarming',
    iconLink: '🏡',
  },
  {
    name: 'Retirement',
    iconLink: '👔',
  },
];
