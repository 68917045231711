import { twMerge } from 'tailwind-merge';

type IProps = {
  htmlFor?: string;
  label: string;
  labelClassName?: string;
};
// HTML for made it focus to other input very hard
export default function Label({ label, labelClassName = '' }: IProps) {
  return (
    <label
      className={twMerge(
        `mb-2 block px-8 text-sm text-gray-710 lg:px-0`,
        labelClassName
      )}
    >
      {label}
    </label>
  );
}
