import { isNull } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '@/components/Layouts/MainLayout';
import ThreeDotsLoading from '@/components/Templates/Loading/ThreeDotsLoading';
import FindTheGiftForm from '@/components/Templates/Popup/FindTheGiftForm';
import SelectGiftType from '@/components/Templates/Popup/SelectGiftType';
import configs from '@/configs';
import { validSteps } from '@/constants/validSteps';
import generateUUID from '@/helpers/generateUUID';
import { localStorage } from '@/helpers/localStorage';
import { paramsToObject } from '@/helpers/paramsToObject';
import { sessionStorage } from '@/helpers/sessionStorage';
import type { AppState } from '@/redux/rootReducer';
import { textPrompt } from '@/services/users/mocks';
import { setCurrentStep, setTextPrompt } from '@/services/users/redux/actions';
import type { ITextPrompt } from '@/services/users/redux/types';

const SetGiftReminderForm = dynamic(
  () => import('@/components/Templates/Popup/SetGiftReminderForm'),
  {
    loading: () => <ThreeDotsLoading loadFullScreen={true} />,
  }
);
const ContentLayout = dynamic(
  () => import('@/components/Layouts/ContentLayout'),
  {
    loading: () => <ThreeDotsLoading loadFullScreen={true} />,
  }
);
export default function Index({ step, isAdsWidgetFlag }: any) {
  const dispatch = useDispatch();
  const router = useRouter();
  const users = useSelector((state: AppState) => state.users);
  const { currentStep } = users;
  const [inputs, setInputs] = useState<ITextPrompt>(users.textPrompt);

  function handleChangeStep(stepVal: number) {
    if (stepVal > 0 && stepVal !== 6) {
      router.push({
        href: '/',
        query: {
          step: stepVal,
          isAdsWidgetFlag: isAdsWidgetFlag === 'true',
        },
      });
    }
    dispatch(setCurrentStep(stepVal));
  }

  useEffect(() => {
    let searchData;
    if (router.query.q) {
      const url = new URL(window.location.href.toString());
      const queryQ = paramsToObject(url.search);
      // @ts-ignore
      const data: any = paramsToObject(queryQ?.q);
      // eslint-disable-next-line no-restricted-syntax
      for (const keyValue in data) {
        if (typeof data[keyValue] === 'string' && data[keyValue].length > 300) {
          router.push('/');
          handleChangeStep(Number(0));
          return;
        }
      }
      searchData = {
        ...data,
        objectives:
          data.objectives.length > 0 ? data.objectives.split(',') : [],
        typesOfGifts:
          data.typesOfGifts.length > 0 ? data.typesOfGifts.split(',') : [],
        isAdsWidgetFlag: isAdsWidgetFlag || false,
      };
      if (typeof window !== 'undefined') {
        // sessionStorage.set(window, 'inputData', JSON.stringify(searchData));
        // Perform localStorage action
        const uuId = localStorage.get(window, 'requestId');
        if (isNull(uuId)) {
          localStorage.set(window, 'requestId', generateUUID());
        }
      }
      dispatch(setTextPrompt(searchData));
    }
    if (typeof step === 'number') {
      const inputDataFromSessionStorage = sessionStorage.get(
        window,
        'inputData'
      );
      if (Number(step) === 8 && Number(step) !== users.currentStep) {
        handleChangeStep(Number(step));
      } else if (
        Number(step) !== users.currentStep &&
        inputDataFromSessionStorage
      ) {
        if (!inputs) {
          setInputs(JSON.parse(inputDataFromSessionStorage));
        }
        handleChangeStep(Number(step));
      } else if (
        !inputDataFromSessionStorage &&
        Number(step) !== 8 &&
        step !== users.currentStep
      ) {
        if (searchData) {
          sessionStorage.set(window, 'inputData', JSON.stringify(searchData));
          if (!inputs) setInputs(searchData);
          dispatch(setTextPrompt(searchData));
          handleChangeStep(Number(step));
        } else {
          router.push('/');
          handleChangeStep(Number(0));
        }
      }
    } else if (users.currentStep !== 0) {
      // handleChangeStep(0);
    }
  }, [router.query]);
  return (
    <MainLayout
      title={`${configs.siteName} | ${configs.title}`}
      description={configs.description}
      step={currentStep}
    >
      <div
        className={`relative z-20 flex w-full flex-1 justify-center px-2 md:h-auto md:grow-0`}
      >
        {step === 0 && (
          <SelectGiftType
            IsAdsWidgetFlag={isAdsWidgetFlag === 'true'}
            handleChangeStep={(stepVal: number) => {
              if (stepVal === 1) {
                sessionStorage.set(
                  window,
                  'inputData',
                  JSON.stringify(textPrompt)
                );
                setInputs(textPrompt);
              }
              handleChangeStep(stepVal);
            }}
          />
        )}

        {step >= 1 && step < 6 && (
          <FindTheGiftForm
            inputData={inputs}
            handleChangeStep={(stepVal: number) => {
              handleChangeStep(stepVal);
            }}
            handleSetInputData={(data) => {
              setInputs(data);
            }}
          />
        )}

        {(step === 6 || step === 7) && (
          <ContentLayout
            IsAdsWidgetFlag={isAdsWidgetFlag === 'true'}
            handleBackScreen={(backStep: number) => {
              if (backStep === 1) {
                sessionStorage.set(
                  window,
                  'inputData',
                  JSON.stringify(textPrompt)
                );
                setInputs(textPrompt);
                handleChangeStep(1);
              } else {
                handleChangeStep(backStep);
              }
            }}
          />
        )}
        {step === 8 && (
          <SetGiftReminderForm
            handleBackScreen={() => {
              handleChangeStep(0);
            }}
            handleChangeStep={() => {
              // handleChangeStep(stepVal);
            }}
          />
        )}
      </div>
    </MainLayout>
  );
}

export async function getServerSideProps(ctx: any) {
  const { query } = ctx;
  const isValidStep = validSteps.includes(Number(query?.step));
  const validStep = isValidStep ? Number(query?.step) : '';
  return { props: { step: validStep || 0 } };
}
