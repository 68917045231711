import { useState } from 'react';

type StepsProgressProps = {
  currentStep?: number;
  totalSteps?: number;
};
export default function StepsProgress({
  totalSteps = 1,
  currentStep = 0,
}: StepsProgressProps) {
  const [steps] = useState(new Array(totalSteps <= 1 ? 2 : totalSteps).fill(1));
  return (
    <ul
      className={
        'relative my-4 inline-flex items-center justify-center gap-6 overflow-hidden text-sm font-semibold text-gray-500'
      }
    >
      {steps.map((_num, index) => {
        let styles = 'border-gray-1050';
        if (index + 1 < currentStep) {
          styles += ' bg-blue-50 text-white border-blue-50';
        } else if (index + 1 <= currentStep) {
          styles += ' border-blue-50 bg-white text-blue-50';
        } else {
          styles += ' bg-white';
        }
        return (
          <li
            key={index}
            className={`relative z-10 flex h-7 w-7 items-center justify-center rounded-full border-1 transition-colors duration-500 ${styles}`}
          >
            {index + 1}
          </li>
        );
      })}
      <li
        style={{
          width: `calc(${((currentStep - 1) / (totalSteps - 1)) * 100}%)`,
        }}
        className={
          'absolute top-1/2 left-0 z-2 h-[1.5px] bg-blue-50 px-[14px] transition-all duration-500'
        }
      />
      <li
        className={
          'absolute top-1/2 left-0 z-1 h-[1.5px] w-full bg-gray-1050 px-[14px]'
        }
      />
    </ul>
  );
}
