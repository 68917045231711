import useDevice from '@/helpers/device';
import type { ITextPrompt } from '@/services/users/redux/types';

type GiftCardProps = {
  name: string;
  field: string;
  iconLink: string;
  handleSetInputData: (data: ITextPrompt) => void;
  inputData: ITextPrompt;
};
export default function GiftCard({
  name,
  field,
  iconLink,
  handleSetInputData,
  inputData,
}: GiftCardProps) {
  const { isMobile } = useDevice();
  let isSelected = false;
  // @ts-ignore
  if (Array.isArray(inputData[field])) {
    // @ts-ignore
    isSelected = inputData[field].some((item) => item === name);
  } else {
    // @ts-ignore
    isSelected = inputData[field] === name;
  }
  return (
    <li
      onClick={() => {
        // @ts-ignore
        if (!Array.isArray(inputData[field])) {
          handleSetInputData({ ...inputData, [field]: name });
          return;
        }
        // @ts-ignore
        const matchedItemIndex = inputData[field].indexOf(name);
        if (matchedItemIndex >= 0) {
          // @ts-ignore
          const clonedData = [...inputData[field]];
          // @ts-ignore
          clonedData.splice(matchedItemIndex, 1);
          handleSetInputData({ ...inputData, [field]: clonedData });
        } else {
          // @ts-ignore
          const clonedData = [...inputData[field]];
          clonedData.push(name);
          handleSetInputData({ ...inputData, [field]: clonedData });
        }
      }}
      className={`${isSelected ? 'bg-blue-50' : ''} ${
        isMobile ? '' : 'hover:bg-blue-50'
      } group col-span-6 inline-flex w-40 cursor-pointer items-center justify-center gap-2 rounded-xl border-1 border-gray-350 py-3 transition-colors  lg:col-span-4`}
    >
      {iconLink && <p className={'text-xl'}>{iconLink}</p>}
      <span
        className={`${isSelected ? 'text-white' : ''} ${
          isMobile ? '' : 'group-hover:text-white'
        } text-sm font-semibold `}
      >
        {name === '>$2,500' ? '$2,500+' : name}
      </span>
    </li>
  );
}
